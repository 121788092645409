<template>

  <div>
    <div class="up"  >
      <div class="uptop"   @click="uptop"></div>
      <div style="font-size: 10px;font-weight:bold;text-align: center;margin-bottom:5px;color:white">Top</div>
      <div style="height: 1px;background-color: rgba(229,227,227,0.3);;width: 100%"></div>
      <div class="tooltip"
           v-if="showTooltip"
           style="width: 108px;height: 108px;position: fixed;right:35px;top: 50%;">
        <img src="../assets/linkedin.png"/>
      </div>
      <div @click="upkefu2" style="padding: 0 0 10px 0;">

        <div  class="upkefu" ></div>
        <div style="font-size: 10px;font-weight:bold;text-align: center;margin-top:5px;color:white"> Ser-<br>vice</div>
      </div>
      <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>

      <div class="feedback"   @click="feedback">Feed-<br>back</div>
      <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
      <div class="wechaticon" @click="show" style="height: 35px;width:20px;display: flex;justify-content: center;text-align: center;padding:10px 0 10px 0;background-color: transparent">

        <img src="@/assets/in.png">
     </div>
    </div>

  </div>

</template>


<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      showTooltip:false
    }

  },

  methods: {
    uptop(){
      this.$emit("topMobile",1)
    },
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    show(){
      console.log("sdian")
      if( this.showTooltip===true){
        this.showTooltip=false
      }
      else{
        this.showTooltip=true
      }

    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452739742062060&webPageTitle=在线咨询")
    },
    upkefu2(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452803868579283&webPageTitle=在线咨询")
    },
    feedback(){
      window.open("/feedbackEn")
      // window.open("https://mall.antonoil.com/gpts_feedback ", "_blank");
    },
  },

  mounted() {


  }

}

</script>


<style type="text/scss" lang="scss" scoped>

.up{
  z-index: 999;
  cursor: pointer;
  position: fixed;
  top:40%;
  right:0;
  background-color: #282e7c;
  padding:0 0 0 1px;
  width: 25px;
  height:fit-content;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  //border-radius: 50%;
  //border: 5px solid #1295d9;
  .uptop{
    width:15px;
    height:15px;
    margin:5px auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu{
    margin:0.5vh auto;
    width:15px;
    height:15px;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .feedback{
    cursor: pointer;
    width: fit-content;
    height:fit-content;
    color: white;
    font-weight: bold;
    font-size: 10px;
    margin:0 auto;
    text-align: center;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
}
</style>
