<template>

  <div class="up"  >
    <div class="uptop"   @click="uptop"></div>
    <div style="font-size: 0.8vw;text-align: center;margin-bottom: 0.8vh;color: white">Top</div>
    <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>

    <div @click="upkefu" style="padding: 0 0 1vh 0;">

      <div  class="upkefu" ></div>
      <div style="font-size: 0.8vw;text-align: center;margin-top: 0.5vh;color: white"> Ser-<br>vice</div>
    </div>
    <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
    <div class="feedback"   @click="feedback">Feed-<br>back</div>
    <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
    <div class="wechaticon" @mouseenter="showTooltip=true"
         @mouseleave="showTooltip=false"
         style="height:4vh;width: 2vw;display: flex;justify-content: center;text-align: center;padding:1vh 0.5vh 0.5vh 0.5vh; margin:0 auto;z-index: 99999">

      <img src="@/assets/in.png">


    </div>
    <div class="tooltip"
         v-if="showTooltip"
         style="width: 15vh;height: 15vh;position: fixed;right: 3.5vw;top: 60vh;">
      <img src="../assets/linkedin.png"/>
    </div>
  </div>

</template>


<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      showTooltip:false,
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    uptop(){
      this.$emit("toptop",1)
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452739742062060&webPageTitle=在线咨询")
    },
    upkefu2(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452803868579283&webPageTitle=在线咨询")
    },
    feedback(){
      window.open("/feedbackEn")
      // window.open("https://mall.antonoil.com/gpts_feedback ", "_blank");
    },
  },

  mounted() {


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.up {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 0;
  padding: 5px;
  width: 3vw;
  height: fit-content;;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background-color: #282d7e;

  //border-radius: 50%;
  box-shadow: 5px 0 14px 0px #D4E4FF;

  .uptop {
    width: 2vw;
    height: 2vw;

    margin: 1vh auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}
</style>
