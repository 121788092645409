/*
 * @Author: gyy
 * @Date: 2023-11-30 22:03:29
 * @LastEditors: 自由命名
 * @LastEditTime: 2023-12-01 19:15:25
 * @FilePath: \zixun-vue\src\utils\vxshare.js
 * @filePurpose:
 */
// import request from 'axios'
import {
  shares
} from "@/api/index";
import wx from "weixin-js-sdk";
// const post_err = (text, tag) => {
//   request({
//     url:"http://192.168.103.198:5002/zyyyzs/",
//     method:"post",
//     data:{
//       "xuhao": text,
//       "cyjt": tag,
//       "cpx": "我是一个产品线",
//       "yy": "我是一个应用",
//       "jzzt": "我是一个进展状态"
//     }
//   })
// }
// 封装分享方法
export function shareUrl(shareData) {
  // var text
  shares(shareData.url).then(res => {
    const config = res.data;
    setTimeout(() => {
      console.log('config')
      console.log(config)
      wx.config({
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'updateAppMessageShareData',
          'updateTimelineShareData'
        ],
        success: function (res) {
          // text+="success:"+JSON.stringify(res)
          // post_err(JSON.stringify(res),"shidoee")
          console.log("success",res)
        },

        fail: function (res) {
          alert(JSON.stringify(res))
          // text+="fail:"+JSON.stringify(res)
          // post_err(JSON.stringify(res),"sssssss")
          console.log("fail")
        }

      });
      console.log(wx.config)
      wx.ready(() => {
        var shareData1 = {
          title: shareData.pageTitle,
          imgUrl: 'https://www.admin.oilgasinfoai.com/api/profile/upload/2024/08/01/IMG_0802_20240801154043A911.PNG',
          desc: shareData.pageDescription,
          link: shareData.url, // 分享后的地址
          success: function () {
            // alert('感谢您的分享')
            // console.log("success")
          },
          fail: function (res) {
            console.log(res)
            // text+="fail2:"+JSON.stringify(res)
            // post_err(JSON.stringify(res),"ssssfail")
            console.log("ssssfail")

          }
        };
        wx.onMenuShareTimeline(shareData1);
        wx.updateAppMessageShareData(shareData1)
        wx.onMenuShareAppMessage(shareData1)
        wx.updateTimelineShareData(shareData1);
      })
      wx.error(() => {
        // text+="error:"+JSON.stringify(res)
        // post_err(text)
      })
    }, 200)

  })
}
