/*
 * @Author: 自由命名
 * @Date: 2023-11-06 14:40:39
 * @LastEditors: 自由命名
 * @LastEditTime: 2023-12-01 14:44:13
 * @FilePath: \zixun-vue\src\system\index.js
 * @filePurpose:
 */
import request from '@/utils/request'

// 获取导航
export function navList() {
  return request({
      url: `/system/system/job_banner/list`,
      method: 'get',
  })
}

//  获取首页新闻列表 /system/category/recommend/{limit}
export function categoryRecommend() {
  return request({
      url: `/system/category/recommend/3`,
      method: 'get',
  })
}


// 首页新闻推荐  /system/recommend/list/{limit}
export function recommend() {
  return request({
      url: `/system/job_space/list`,
      method: 'get',
  })
}

//  获取首页商机洞察 /system/news/businessList
export function businessList() {
  return request({
      url: `/system/news/businessList`,
      method: 'get',
      params:{
        limit:5
      }
  })
}

//  获取首页行业报告列表 /system/report/list
export function reportList() {
  return request({
      url: `/system/report/list`,
      method: 'get',
      params:{
        pageNum: 1,
        pageSize:6
      }
  })
}


// 尾部底部导航数据  /system/column/column
export function columnNav() {
  return request({
      url: `/system/column/column`,
      method: 'get',
  })
}

// 尾部联系我们数据  /system/column/contact
export function columnContact() {
  return request({
      url: `/system/column/contact`,
      method: 'get',
  })
}

// 尾部二维码数据  /system/column/qrcode
export function columnQrcode() {
  return request({
      url: `/system/column/qrcode`,
      method: 'get',
  })
}

// 微信分享配置 /system/wxShare
export function shares(data) {
  return request({
      url: `/wx/GptswxShare`,
      method: 'get',
      params:{
        requestUrl: data
      }
  })
}
// 登录   获取验证码
export function randomImage(id) {
  return request({
      url: `/thirdPlatform/home/randomImage/${id}`,
      method: 'get',
  })
}
