<template>
  <div>
    <div v-if="isMobile===false">
      <el-dialog
          :before-close="handleClose"
          :visible.sync="dialogVisible"
          top="15vh"
          width="45vw"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">{{ thisTitle }}</span><span>&nbsp;Product Inquiry</span></div>
          <div class="title2">We will contact you as soon as possible!</div>
          <div style="margin-top: 30px">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="180px">
              <el-form-item label="Company" prop="companyName">
                <el-input v-model="ruleForm.companyName" placeholder="Please enter your company name"></el-input>
              </el-form-item>

              <el-form-item label="Name" prop="userName">
                <el-input v-model="ruleForm.userName" placeholder="Please enter your name"></el-input>
              </el-form-item>
              <el-form-item label="Phone" prop="contact">
                <el-input v-model="ruleForm.contact" placeholder="
Please enter your phone number"></el-input>
              </el-form-item>
              <el-form-item label="Cooperation" prop="content">
                <el-input v-model="ruleForm.content" placeholder="Please provide a detailed description of the cooperation content or products"
                          type="textarea"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center">

              </el-form-item>
            </el-form>
          </div>
          <div style="text-align: center">
            <el-button type="primary" @click="submitForm(ruleForm)">Submit</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div v-if="isMobile===true" class="phone" style="font-size: 14px">
      <el-dialog
          :before-close="handleClose"
          :visible.sync="dialogVisible"
          width="350px"
          top="15px"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF;margin-top: 25px">{{ thisTitle }}</span><span>&nbsp;<br>Product Inquiry</span></div>
          <div class="title2">We will contact you as soon as possible!</div>
          <div style="margin-top: 20px">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="120px">
              <el-form-item label="Company" prop="companyName">
                <el-input v-model="ruleForm.companyName" placeholder=" company name"></el-input>
              </el-form-item>
              <div class="span"></div>
              <el-form-item label="Name" prop="userName">
                <el-input v-model="ruleForm.userName" placeholder="your name"></el-input>
              </el-form-item>
              <div class="span"></div>
              <el-form-item label="Phone" prop="contact">
                <el-input v-model="ruleForm.contact" placeholder="phone number"></el-input>
              </el-form-item>
              <div class="span"></div>
              <el-form-item label="Cooperation" prop="content">
                <el-input v-model="ruleForm.content" placeholder="Please provide a detailed description of the cooperation content or products"
                          type="textarea"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center">
              </el-form-item>
              <div class="span"></div>
            </el-form>
          </div>
          <div style="text-align: center">
            <el-button type="primary" @click="submitForm(ruleForm)" >Submit</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <successTip
        :dialogVisible="dialogconsult"
        @hideDialog="hide"
    ></successTip>
  </div>

</template>
<script>
import successTip from "@/components/successTip.vue";
export default {
  components: {successTip,},
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    thisTitle: {
      type: String,
      // required: true
    }
  }, data() {
    return {
      userId:localStorage.getItem("userId"),
      complete:localStorage.getItem("infoComplete"),
      dialogconsult: false,
      token: localStorage.getItem("access_token"),
      isMobile: false,
      languageSelect: '',
      ruleForm: {
        companyName: '',
        userName: '',
        contact: '',
        content: '',
        consultType: 1,
        consultUrl: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      rules: {
        companyName: [
          { required: true, message: 'Please enter your company name', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: 'Please enter your name', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: 'Please enter your phone number or email', trigger: 'blur' }
        ],


      }
    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }

  },
  methods: {
    hideDialogin() {
      // this.dialogVisibleLogin = false;
      // location.reload()
      this.$forceUpdate()
    },
    hide() {
      this.dialogconsult = false;
      // location.reload()
      this.$forceUpdate()
    },
    hideComplete(){
      // location.reload()
      this.$forceUpdate()
      // this.dialogComplete = false;
      this.dialogVisible=false
    },

    handleClose() {
      this.ruleForm.companyName = ""
      this.ruleForm.userName = ""
      this.ruleForm.contact = ""
      this.ruleForm.content = ""
      this.$emit('hideDialog')
    },
    submitForm(formName) {
      formName.consultUrl = window.location.href
      console.log(formName)
      if(formName.companyName===""){
        this.$message('Please enter your company name');
      }
      else if(formName.userName===""){
        this.$message('Please enter your name');
      }
      else if(formName.contact===""){
        this.$message('Please enter your phone number or email');
      } else {
        this.$axios.post("/api/consult/add", {
          companyName: formName.companyName,
          userName: formName.userName,
          contact: formName.contact,
          content: formName.content,
          consultType: 1,
          consultUrl: formName.consultUrl,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token
          },
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.dialogconsult = true
                this.handleClose()
                this.resetForm(formName)
              } else {
                this.$message('Submission failed, please try again.');
              }

            }).catch(error => {
          console.log(error)
        })

      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    "$store.state.userId"(newValue) {
      this.userId = newValue;
    },

  },

};
</script>
<style lang="scss" scoped>

.title1{
  text-align: center;
  width: 100%;
  font-size: 2vw;
  margin-top: -35px;
}
.title2{
  color:gray;
  text-align: center;
  width: 100%;
  font-size: 1.5vw;
  margin-top: 15px;
}
.phone {
  position: fixed;
  z-index: 9999999999;

  .title1 {
    //text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-top: -35px;
  }
  .title2 {
    font-size: 16px;
  }
  ::v-deep .el-form-item__error {
    left: 15px !important;
    top: 80%;
  }

  ::v-deep .el-textarea__inner {
    border: none !important;
    margin-top: 5px;
    height: 85px;
    font-size: 14px;
    font-family: Instrument Sans,Arial;
  }
  ::v-deep .el-input__inner{
    height: 50px !important;
    font-size: 16px !important;
  }
  ::v-deep .el-textarea__inner{
    font-size: 16px !important;
  }
  ::v-deep .el-form-item__label{
    font-size: 16px !important;
  }
  .el-form-item {
    margin-bottom: 10px !important;
    font-size: 16px !important;
  }

  .span {
    height: 1px;
    background-color: #e1d7d7;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .title2 {
    color: gray;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }

  ::v-deep {
    .el-dialog__body {
      padding: 30px 5px 30px 5px;
      //color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-dialog__header {
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }

    .el-input__inner {
      font-size: 14px;
      font-family: Instrument Sans,Arial;
      border: none !important;

    }
    .el-dialog__headerbtn {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0;
      background: 0 0;
      border: none;
      outline: 0;
      cursor: pointer;
      font-size: 16px;
    }
    .el-textarea {
      border-radius: 4px;
      line-height: 20px;
      border: none !important;
      position: relative;
      font-size: 14px;
    }

    .el-form-item__label {
      font-size: 14px;
    }

    .el-button {
      width: fit-content;
      height: fit-content;
      padding: 5px 25px;
      margin-top: 25px;
      line-height: 30px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      color: #2F6EFF;

      a {
        display: block;
      }
    }

    .el-button--primary {
      border: 1px solid #2F6EFF;
      background: #2F6EFF !important;
      color: #fff !important;
      margin-bottom: unset !important;
    }

  }

  ::v-deep {
    .el-dialog {
      margin: auto !important;
      font-size: 14px;

      top: 50%;
      transform: translateY(-50%);
    }

    .el-dialog__header {
      padding: 5px;

      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 14px;

      }

    }


    .el-button {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      padding: 5px 15px;
      line-height: 20px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;

      a {
        display: block;
      }
    }

  }

}
::v-deep {
  .el-dialog__body {
    padding: 30px 20px 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-dialog__header {
    .el-dialog__title {
      font-weight: bold;
      font-size: 14px;
    }
  }
  .el-dialog__headerbtn {
    position: absolute;
    top: -30px;
    right: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .el-dialog__headerbtn .el-dialog__close {

    color:white;;
    border: white 1px solid;
    border-radius: 50%;
  }
  .el-input__inner {
    font-size: 1.2vw;
    font-family: Instrument Sans,Arial;
    border-radius: 5px !important;
    border: 1px solid #9b9a9a !important;
    height: 8vh !important;

  }
  .el-textarea {
    line-height: 40px;
    border-radius: 5px !important;
    border: 1px solid #9b9a9a !important;
    position: relative;
    font-size: 1.2vw !important;
  }
  .el-form-item__label {
    font-size: 1.5vw;
  }
  .el-button {
    border: 1px solid #2F6EFF;
    background: #2F6EFF !important;
    color: white !important;
    padding: 2vh 0 !important;
    a {
      display: block;
    }
  }

  .el-textarea__inner {

    font-size: 1.2vw;
    font-family: Instrument Sans,Arial;
  }


}
</style>
