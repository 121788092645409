const DISTANCE = 150;
const DURATION = 1000;
const animationMap = new WeakMap();
const ob = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        console.log('Entry:', entry);
        if (entry.isIntersecting) {
            const animation = animationMap.get(entry.target);
            console.log('Playing animation for:', entry.target);
            animation.play();
            ob.unobserve(entry.target);
        }
    }
});

export default {
    mounted(el) {
        alert("进来了");

        const animation = el.animate(
            [
                {
                    transform: `translateY(${DISTANCE}px)`,
                    opacity: 0,
                },
                {
                    transform: 'translateY(0)',
                    opacity: 1,
                }
            ], {
                duration: DURATION,
                easing: 'ease-in'
            }
        );
        animation.pause();
        animationMap.set(el, animation);
        ob.observe(el);
    }
}